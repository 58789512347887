<template>
  <div>
    <header class="page-header">
      <PageTitle/>
      <div class="buttons">
        <router-link :to="{name: 'admin.newsitem.add'}" class="btn btn-add" v-if="checkPermissions(['news.create'])">
          <span>{{ $t('Nieuwsbericht aanmaken') }}</span>
        </router-link>
      </div>
    </header>

    <div class="main-content">
      <div class="pane table-pane">
        <div class="table-wrapper">
          <div class="table-element">
            <table>
              <thead>
              <tr>
                <th class="id">{{ $t('ID') }}</th>
                <th>{{ $t('Titel') }}</th>
                <th>{{ $t('Highlight') }}</th>
                <th>{{ $t('Subhighlight') }}</th>
                <th>{{ $t('Aangemaakt') }}</th>
              </tr>
              </thead>

              <tbody v-if="news.length > 0">
              <tr :key="newsItem.id" @click="$router.push({name: 'admin.newsitem', params: {id: newsItem.id}})" @contextmenu.prevent.stop="newsRightClick($event, newsItem)" v-for="newsItem in news">
                <td class="id">{{ newsItem.id }}</td>
                <td>{{ getTranslation(newsItem).title }}</td>
                <td><img width="15" v-if="newsItem.highlight" src="@/assets/img/check.svg" alt="check">&nbsp;</td>
                <td><img width="15" v-if="newsItem.sub_highlight" src="@/assets/img/check.svg" alt="check">&nbsp;</td>
                <td>{{ newsItem.created.setLocale('nl').toFormat('dd/LL/yyyy') }}</td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="pagination">
            <div class="results">
              {{ $t(`${pageTotal.min} - ${pageTotal.max} van ${total} nieuwsberichten`) }}
            </div>

            <div class="buttons">
              <button class="btn-inverse" :disabled="page === 0" @click="prevPage()">Prev</button>
              <button class="btn-inverse" :disabled="news.length !== perPage" @click="nextPage()">Next</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <vue-simple-context-menu element-id="vueSimpleContextMenu" :options="contextMenuOptions" ref="vueSimpleContextMenu" @option-clicked="contextOptionClicked"></vue-simple-context-menu>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { notification } from '@/notifications'
import { ApiService } from '@/services/admin/api.service'
import PageTitle from '@/components/admin/PageTitle'

export default {
  components: { PageTitle },
  data: function () {
    return {
      contextMenuOptions: [{ id: 'deleteNewsItem', name: 'Nieuwsbericht verwijderen' }]
    }
  },
  async mounted () {
    await this.fetchNewsItems(this.page)
  },
  methods: {
    newsRightClick: async function (event, newItem) {
      if (this.checkPermissions(['news.delete'])) {
        this.$refs.vueSimpleContextMenu.showMenu(event, newItem)
      }
    },
    contextOptionClicked: async function (event) {
      await this.deleteNewsItem(event.item.id)
      await this.$store.dispatch('NewsModule/fetchNews')
    },
    deleteNewsItem: async function (newsItemId) {
      ApiService.deleteNewsItem(newsItemId).then(async () => {
        notification('Nieuwsbericht succesvol verwijderd.')
        await this.fetchNewsItems(this.page)
      })
    },
    prevPage: async function () {
      if (this.page > 0) {
        await this.fetchNewsItems(this.page - 1)
      }
    },
    nextPage: async function () {
      if (this.news.length === this.perPage) {
        await this.fetchNewsItems(this.page + 1)
      }
    },
    async fetchNewsItems (page) {
      await this.$store.dispatch('NewsModule/fetchNews', { page: page })
    }
  },
  computed: {
    total: function () {
      return this.$store.state.NewsModule.total
    },
    page: function () {
      return this.$store.state.NewsModule.page
    },
    perPage: function () {
      return this.$store.state.NewsModule.perPage
    },
    news: function () {
      const news = []
      const rawNews = this.$store.getters['NewsModule/getNews']

      if (rawNews) {
        rawNews.forEach((newsItem, i) => {
          const item = {}

          for (const prop in newsItem) {
            if ((prop === 'created' || prop === 'updated') && newsItem[prop] != null) {
              item[prop] = DateTime.fromISO(newsItem[prop])
            } else {
              item[prop] = newsItem[prop]
            }
          }

          news.push(item)
        })
      }

      return news
    },
    pageTotal: function () {
      let offsetMax = this.perPage * (this.page + 1)
      const offsetMin = offsetMax - this.perPage + 1

      if (offsetMax > this.total) {
        offsetMax = this.total
      }

      return { min: offsetMin, max: offsetMax }
    }
  }
}
</script>
